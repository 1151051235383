import { createTheme, PaletteMode } from '@mui/material';

export const blueTheme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode,
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: mode === 'dark' ? '#080808' : '#eeeeee',
            backgroundImage:
              'url(https://source.unsplash.com/1600x900/?btc&eth&usdt)',
            backgroundBlendMode: 'soft-light',
            backgroundSize: 'cover',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: '1px solid rgb(30, 73, 118)',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#081627',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            'boxShadow': 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(255,255,255,0.15)',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#4fc3f7',
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            'color': 'rgba(255, 255, 255, 0.7)',
            '&:hover, &:focus': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
            },
            'boxShadow': '0 -1px 0 rgb(255,255,255,0.1) inset',
            'py': 1.5,
            'px': 3,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        },
      },
    },
  });
